import React, { useEffect } from 'react'
import './Client.css'
import s1 from './c1.webp'
import s2 from './c2.jpg'
import s3 from './c3.jpg'
import s4 from './c4.jpg'
import s5 from './c5.jpeg'
import s6 from './c6.jpg'
import s7 from './c7.jpg'
import s8 from './c8.jpg'
import s9 from './c9.png'
import s10 from './c10.png'
import s11 from './c11.png'
import s12 from './1.png'
import s13 from './2.png'
import s14 from './3.png'
import s15 from './4.png'
import s16 from './5.png'
import s17 from './6.png'
import s18 from './7.png'
import s19 from './8.png'
import s20 from './9.png'
import s21 from './10.png'
import s22 from './11.png'
import s23 from './12.png'

function Client() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <section className='clientpage-section'>
            <div className="container">
                <div className="bg"></div>
                <div className="client-main-container">
                    <div className="heading">
                        <span>Our Clients</span>
                    </div>
                    <div className="main-container">
                        <div className="col-1 same-col">
                            <img src={s1} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s2} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s3} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s4} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s5} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s6} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s7} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s8} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s9} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s10} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s11} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s12} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s13} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s14} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s15} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s16} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s17} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s18} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s19} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s20} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s21} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s22} alt="" />
                        </div>
                        <div className="col-1 same-col">
                            <img src={s23} alt="" />
                        </div>
                        {/* <div className="col-1 same-col">
                            <img src={s24} alt="" />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Client
